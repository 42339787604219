"use client";
import React, { createContext, useContext, useState, useEffect } from "react";
import { Bettor, BettorProfile } from "common";
import { useUser } from "@clerk/nextjs";
import { getProfile } from "@/utils/bettor";

interface BettorContextType {
  bettor: BettorProfile | null;
  setBettor: (bettor: BettorProfile | null) => void;
}

const BettorContext = createContext<BettorContextType>({
  bettor: null,
  setBettor: () => {},
});

export const useBettor = () => {
  const context = useContext(BettorContext);
  if (!context) {
    throw new Error("useBettor must be used within a BettorProvider");
  }
  return context;
};

export function BettorProvider({
  bettor: initialBettor,
  children,
}: {
  bettor: BettorProfile | null;
  children: React.ReactNode;
}) {
  const [bettor, setBettor] = useState<BettorProfile | null>(initialBettor);

  const { isSignedIn, user, isLoaded } = useUser();

  useEffect(() => {
    console.log("user changed", bettor, isLoaded, isSignedIn);
    if (bettor !== null) return;
    if (isLoaded && isSignedIn) {
      if (user.id) {
        (async () => {
          try {
            const bettorData = await getProfile(user.id, true);
            setBettor(bettorData);
          } catch (error) {
            console.error("Error getting bettor data", error);
            setBettor(null);
          }
        })();
      } else {
        setBettor(null);
      }
    }
  }, [user, isLoaded, isSignedIn]);

  return (
    <BettorContext.Provider value={{ bettor, setBettor }}>
      {children}
    </BettorContext.Provider>
  );
}
