import { Bettor, BettorProfile } from "common";

export function buildProfileRequest(username: string, clerk?: boolean): string {
  return `${process.env.NEXT_PUBLIC_API_URL}/bettors${clerk ? "/clerk" : ""}/${clerk ? username : username.toLowerCase()}/profile`;
}

/** Accepts bettor username or clerk user_id */
export async function getProfile(username: string, clerk?: boolean): Promise<BettorProfile> {
  const res = await fetch(
    buildProfileRequest(username, clerk),
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      cache: "no-store",
      next: {
        tags: ["recent-picks"],
      }
    });
  if (!res.ok) {
    throw new Error("Failed to fetch bettor profile");
  }
  return res.json();
}

/** Accepts bettor username or clerk user_id */
export async function deactivateBettor(username: string, clerk?: boolean): Promise<BettorProfile> {
  const res = await fetch(
    buildProfileRequest(username, clerk),
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      cache: "no-store",
      next: {
        tags: ["recent-picks"],
      }
    });
  if (!res.ok) {
    throw new Error("Failed to fetch bettor profile");
  }
  return res.json();
}
