"use client";
import MegaMenu, { LegalStateMenuItem } from "./mega-menu";
import React from "react";
import Link from "next/link";
import UserDropdown from "../bettor/user-dropdown";
import MobileMenu from "./mobile-menu";
import { MenuItem } from "./menu-data";
import Icon from "../primitives/icon";
import { SignedOut, SignedIn, useUser } from "@clerk/nextjs";

interface NavbarProps {
  sportsbookMenuItems: MenuItem[];
  legalStatesMenuItems: LegalStateMenuItem[];
}

export default function Navbar(props: NavbarProps) {
  return (
    <div className="fixed h-20 top-0 left-0 right-0 z-50 bg-secondary-100">
      <div className="h-full container flex gap-8 justify-between items-center">
        <div className="flex desktop-menu:hidden w-[3.75rem]">
          <MobileMenu legalStatesMenuItems={props.legalStatesMenuItems} />
        </div>
        <div className="flex select-none">
          <Link href="/" prefetch={true}>
            <img src="/images/logo.svg" alt="Moneyline Logo" className="pt-3" />
          </Link>
        </div>
        <MegaMenu
          sportsbookMenuItems={props.sportsbookMenuItems}
          legalStatesMenuItems={props.legalStatesMenuItems}
        />

        <UserControls />
      </div>
    </div>
  );
}

function UserControls() {
  const { isLoaded } = useUser();
  if (!isLoaded) {
    return (
      <div className="flex gap-2 items-center justify-center">
        <div className="w-8 h-8 flex justify-center items-center bg-secondary-400 rounded-full animate-pulse"></div>
        <Icon name="arrow-button-down" className="fill-white animate-pulse" />
      </div>
    );
  }
  if (isLoaded) {
    return (
      <>
        <SignedIn>
          <LoggedIn />
        </SignedIn>
        <SignedOut>
          <NotLoggedIn />
        </SignedOut>
      </>
    );
  }
}

function LoggedIn() {
  return (
    <>
      <div className="flex items-center gap-6">
        <div className="hidden desktop-menu:flex">
          {/* <Notification.Trigger /> */}
        </div>
        <UserDropdown></UserDropdown>
      </div>
    </>
  );
}

function NotLoggedIn() {
  // const { setAuth } = useContext(AuthContext);
  return (
    // <>
    //   <div className="items-center gap-4 hidden desktop-menu:flex">
    //     <Button
    //       color="light"
    //       mode="bordered"
    //       prefetch={true}
    //       onClick={() => {
    //         setAuth("login");
    //       }}
    //     >
    //       Login
    //     </Button>
    //     <Button
    //       prefetch={true}
    //       color="primary"
    //       onClick={() => {
    //         setAuth("register");
    //       }}
    //     >
    //       Create Account
    //     </Button>
    //   </div>
    //   <div className="flex desktop-menu:hidden">
    //   </div>
    // </>
    <UserDropdown></UserDropdown>
  );
}
